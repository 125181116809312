<template>
    <div class="home-about-form">
        <div class="form-container">
            <div class="form-body">

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>标题</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入标题" v-model:value="formData.title" />
                    </div>
                </div>

                <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>内容</span>
                                <em>*</em>
                            </div>
                        </div>
                        <div class="item">
                            <n-textarea 
                                v-model:value="formData.content"
                                :maxlength="340"
                            ></n-textarea>
                        </div>
                    </div>

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>图片</span>
                                <em>*</em>
                            </div>
                        </div>
                        <div class="item">
                            <n-upload v-model:src="formData.img" width="auto" :height="400" />
                        </div>
                    </div>

            </div>
        </div>
    </div>

    <div class="bottom-tools-box">
        <n-button class="tool-btn" size="large" @click="location.reload()">重置</n-button>
        <n-button 
            class="tool-btn" 
            size="large" 
            type="primary" 
            :loading="btnLoading"
            :disabled="formData.title === '' || formData.img === '' || formData.content === ''"
            @click="$emit('submit', formData)"
        >确定</n-button>
    </div>  
</template>

<script>
export default {
    name: 'AboutForm',
    props: {
        data:{
            type: Object,
            default: {}
        },
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            formData: {
                title: '',
                content: '',
                img: ''
            }
        }
    },
    watch:{
        'data':{
            handler(){
                this.formData = this.data;
            },
            deep: true
        }
    },
    emits: ['submit']
}
</script>

<style lang="scss" scoped>
@import 'form-style';
</style>