<template>
    <breadcrumb />
    <div class="page-about">
        <about-form :btnLoading="loading" :data="about" @submit="submit" />
    </div>
    <n-notification 
        v-model:show="notification.show"
        :content="notification.content"
    >
        <template v-slot:icon>
            <attention v-if="notification.state === 'error'" theme="outline" size="24" fill="#ff2121"/>
            <check-one v-else theme="outline" size="24" fill="#00c12b"/>
        </template>
    </n-notification>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import AboutForm from '@/components/forms/AboutForm.vue';
import { Attention, CheckOne } from '@icon-park/vue-next';
export default {
    name: 'RecommendAbout',
    components: {
        Breadcrumb, AboutForm,
        Attention, CheckOne 
    },
    data(){
        return {
            about: {
                title: '',
                content: '',
                img: ''
            },
            loading: false,
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        getData(){
            this.$api.xrHomeAbout().then(res => {
                if(res.data) this.about = res.data;
            })
        },
        submit(e){
            this.loading = true;
            this.$api.xrHomeAboutUpdate(e).then(res => {
                this.loading = false;
                if(res.msg === 'success'){
                    this.notification = {
                        show: true,
                        content: '成功',
                        state: 'success'
                    }
                    setTimeout(() => {
                        this.$router.go(-1);
                    },5000);
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    }
}
</script>